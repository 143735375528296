const pageURL = window.location.href

/**
 * IF SHIPPING PAGE
 * If a donation only basket then automatically select a standard delivery option and
 * submit the form. Show a loading screen on the page to avoid confusion for the customer.
 */
if (pageURL.includes('cart/shipping')) {
  // CHECK FOR DONATION ONLY BASKET
  const timer = document.getElementById('tn-count-down-timer')
  if (timer.dataset.tnMillisecondsRemaining === '0') {
    /**
     * HIDE PARTS OF THE PAGE
     * Hide delivery options and subheader
     */
    const subHeaderText = document.getElementsByClassName('tn-heading-sub-text')[0]
    subHeaderText.style.height = 0
    subHeaderText.style.overflow = 'hidden'

    const shippingOptions = document.getElementsByClassName('row')[0]
    shippingOptions.style.height = 0
    shippingOptions.style.overflow = 'hidden'

    const pageHeading = document.getElementById('tn-page-heading')
    pageHeading.innerHTML = 'Loading ...'

    // PRINT AT HOME
    document.getElementsByClassName('tn-shipping-method-3')[0].checked = true
    const deliveryForm = document.getElementById('tn-shipping-form')
    deliveryForm.submit()
  }
}

/**
 * IF PAYMENT PAGE
 * If a donation only basket then automatically hide the delivery options on the
 * page. This overrides any default behaviour for gift certificates.
 */
if (pageURL.includes('cart/payment')) {
  // CHECK FOR DONATION ONLY BASKET
  const timer = document.getElementById('tn-count-down-timer')
  if (timer.dataset.tnMillisecondsRemaining === '0') {
    const shippingInformation = document.getElementsByClassName('tn-patron-shipping-information')[0]
    shippingInformation.style.display = 'none'
  }
}
