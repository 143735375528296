/**
 * CYOP SYOS CUSTOMISATION
 * Add functionality to edit pre-selected seats when in CYOP mode.
 * Additional functionality on top of TNEW:
 * - Display call to action on individual performances in cart to amend seats
 * - Call to action links to the performance SYOS in TNEW
 * - Preload screen and display seats already selected
 * - When a customer chooses new seats trigger API call to process updating cart
 * - Return to cart to display new seats chosen
 */

import * as CYOPCart from './cart'

// FETCH SESSION KEY FROM DATA LAYER
const sessionKey = window.dataLayer.find(function (e) { return e.hasOwnProperty('session') }).session.key
const sessionKeyURL = btoa(sessionKey)

// GET PAGE URL
const pageURL = window.location.href

/**
 * BASKET BUTTON
 * If on the cart/details page find all packages and loop through them,
 * check for which ones are flex packages and can be edited and then apply
 * the amends seats button.
 */
if (pageURL.includes('cart/details')) {
  // GET ALL PACKAGES
  const packageItems = document.querySelectorAll('.tn-cart-item--package')
  if (packageItems.length > 0) {
    console.log('api request')
    // GET CART API
    const xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        const cartAPI = JSON.parse(this.response)
        console.log(packageItems)
        packageItems.forEach(packageItem => {
          // PACKAGE TYPE
          const editPackageElement = packageItem.getElementsByClassName('tn-cart-item-summary__action-item--edit-package')[0]
          if (editPackageElement) {
            const editPackageElementLink = editPackageElement.getElementsByTagName('a')[0].href
            // IF FLEX PACKAGE TYPE
            if (editPackageElementLink.includes('flex')) {
              // BUTTON DATA
              const packageId = packageItem.getElementsByClassName('tn-cart__btn-remove')[0].dataset.tnDeleteId
              const cartItems = packageItem.querySelectorAll('.tn-cart-item--in-package')
              const cartAPIPackageProduct = cartAPI.Products
                .filter(product => product.Package && product.Package.Id === parseInt(packageId, 10))[0].Package

              // GENERATE BUTTON
              CYOPCart.button(packageId, cartItems, cartAPIPackageProduct)
            }
          }
        })
      }
    }

    // Get Basket
    xhttp.open('GET', 'https://api.santafeopera.org/santa-fe/en/checkout-service/basket?tnewqa=' + sessionKeyURL, true)
    xhttp.send()
  }
}

/**
 * SYOS SKIP TO SCREEN
 * When you land on the SYOS with the CYOP param
 * in the URL then attempt to use localStorage to load current seats.
 */
if (pageURL.includes('?custom=cyop')) {
  const CYOPData = JSON.parse(localStorage.getItem('cyop_syos'))
  if (CYOPData) {
    // Hide best available link
    const bestLink = document.getElementsByClassName('tn-ticketing-mode-change__content')[0]
    bestLink.style.display = 'none'

    // Create CYOP detail block
    const ticketHeader = document.getElementsByClassName('tn-ticketing-mode-change')[0]
    const cyopDetailBox = document.createElement('div')
    cyopDetailBox.classList.add('custom-cyop-detail')
    cyopDetailBox.innerHTML = '<p><strong>Package Editor</strong></p><p>Your current seats are highlighted on the map with the following symbol <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" role="img" focusable="false" aria-label="Circled dot" width="12" style="width: 12px; margin: 0 3px 0 0;"><circle cx="25" cy="25" r="24"></circle><circle cx="25" cy="25" r="21" fill="#FFF"></circle><circle cx="25" cy="25" r="10"></circle></svg>. If you would like to change your seats please select the alternative seats and click UPDATE CART below the Seatmap. If you want to keep your current seats <a href="/cart/details">click here</a>.</p><hr><p><strong>Current Seats</strong></p>'
    CYOPData.seats.forEach(seat => {
      cyopDetailBox.innerHTML += `<p>Row: ${seat.Seat.Row} Seat: ${seat.Seat.Number}</p>`
    })
    ticketHeader.append(cyopDetailBox)

    /**
     * MUTATION OBSERVER
     */
    const syosContainer = document.querySelector('.tn-syos')
    let screenLoaded = false
    let displaySeats = false

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // LOAD SCREEN
        if (document.querySelector('#smap-83') && mutation.type === 'childList') {
          if (screenLoaded === false) {
            console.log('load screen')
            window.scrollTo(0, 0)
            // eslint-disable-next-line no-new
            new window.tnew.syos.SyosController(CYOPData.instanceId, CYOPData.screenId)
            screenLoaded = true
          }
        }

        // DISPLAY SEATS
        if (document.querySelector('.tn-syos-seat-map') && mutation.type === 'childList') {
          if (displaySeats === false) {
            console.log('display seats')
            window.scrollTo(0, 400)
            // eslint-disable-next-line no-new
            CYOPData.seats.forEach(seat => {
              const seatElement = document.querySelectorAll('[data-tn-seat-id="' + String(seat.Seat.Id) + '"]')[0]
              if (seatElement) {
                setTimeout(function () {
                  seatElement.setAttribute('xlink:href', '#tn-syos-seat-map-icon-donut')
                  seatElement.style.fill = '#000'
                  seatElement.style.stroke = '#000'
                }, 10)
              }
            })

            // FORCE POSITIONING
            document.getElementsByClassName('tn-events-detail-page')[0].style.marginBottom = '600px'
            document.getElementsByClassName('c-global-footer')[0].style.minHeight = 0

            // Remove add to cart button
            const addToCartButton = document.getElementsByClassName('tn-syos__btn-add-to-cart')[0]
            addToCartButton.style.display = 'none'
            addToCartButton.remove()

            // Add update to cart button
            const syosControls = document.getElementsByClassName('tn-syos-mobile-cart-controls')[0]
            const updateCartButton = document.createElement('button')
            updateCartButton.classList.add('tn-syos__btn-add-to-cart', 'custom-add-to-cart')
            updateCartButton.innerHTML = 'UPDATE CART'
            syosControls.append(updateCartButton)

            // Handle onclick
            updateCartButton.addEventListener('click', function () {
              // Get selected seats
              const seatList = document.getElementsByClassName('tn-syos-cart-seat__list')
              const seatsSelected = Array.from(seatList)

              // Block builder (used for error and loading)
              const layoutContainer = document.getElementsByClassName('tn-syos__layout-container')[0]
              const newContainerElement = document.createElement('div')
              newContainerElement.classList.add('custom-cyop-background')

              // Error - Incorrect number of seats selected
              if (seatsSelected.length !== CYOPData.seats.length) {
                const errorVariance = (seatsSelected.length < CYOPData.seats.length) ? 'fewer' : 'more'
                const seatPlural = (CYOPData.seats.length > 1) ? 'seats' : 'seat'
                newContainerElement.innerHTML = `
                  <div class="custom-cyop-busy-indicator">
                    <div class="custom-cyop-busy-indicator__content">
                      <div class="custom-cyop-busy-indicator__message">You have selected ${errorVariance} seats than originally in the cart. Please select <strong>${CYOPData.seats.length}</strong> ${seatPlural}. To remove tickets from selection simply click on them on the map again.</div>
                      <button id="custom-error-close">OK</button>
                    </div>
                  </div>
                `
                layoutContainer.prepend(newContainerElement)

                // Close error
                const closeErrorButton = document.getElementById('custom-error-close')
                closeErrorButton.addEventListener('click', function () {
                  newContainerElement.style.display = 'none'
                  newContainerElement.remove()
                })

                return
              }

              // Trigger load
              newContainerElement.innerHTML = `
                <div class="custom-cyop-busy-indicator custom-cyop-busy-indicator--spinning">
                  <div class="custom-cyop-busy-indicator__content">
                    <div class="custom-cyop-busy-indicator__message">Loading</div>
                  </div>
                </div>
              `
              layoutContainer.prepend(newContainerElement)

              // Set arrays
              const seatIds = []
              const priceTypes = []
              seatsSelected.forEach(seat => {
                // Seat Id
                const seatDataSet = JSON.parse(JSON.stringify(seat.nextElementSibling.dataset))
                const seatId = parseInt(seatDataSet.tnSeatId, 10)
                seatIds.push(seatId)

                // Price type Id
                const priceTypeName = seat.childNodes[5].innerText
                let priceTypeId = 4
                if (priceTypeName.includes('Accessible')) {
                  priceTypeId = 450
                } else if (priceTypeName.includes('Wheelchair')) {
                  priceTypeId = 456
                }
                priceTypes.push(priceTypeId)
              })
              const seatIdsOutput = seatIds.join(',')
              const priceTypesOutput = priceTypes.join(',')

              // Add to cart
              const data = {
                encryptedSessionKey: sessionKey,
                packageId: CYOPData.packageId,
                instanceId: CYOPData.instanceId,
                priceTypes: priceTypesOutput,
                seatIds: seatIdsOutput
              }

              // Encode data
              const toUrlEncoded = obj => Object
                .keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
                .join('&')
              const dataString = toUrlEncoded(data)

              // Post Request
              const xhr = new XMLHttpRequest()
              xhr.open('POST', 'https://api.santafeopera.org/santa-fe/en/ticketing-service/add-tickets?tnewqa=' + sessionKeyURL, true)
              xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
              xhr.onload = function () {
                window.location.href = '/cart/details'
                console.log(JSON.parse(this.response))
              }
              xhr.send(dataString)
            })
            displaySeats = true
          }
        }
      })
    })
    observer.observe(syosContainer, {
      subtree: true,
      childList: true
    })
  }
}
