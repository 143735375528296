/**
 * MAP SCREEN ID
 * Link to correct screen based on row and seat number
 * @param {object} rootSeat
 */
const mapScreenId = (rootSeat) => {
  // ROOT SEAT SHORTHAND
  const seatRow = rootSeat.Row
  const seatNumber = parseInt(rootSeat.Number, 10)

  // ROWS ARRAYS
  const frontRows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']
  const middleRows = ['P', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'BB', 'CC', 'DD', 'EE', 'FF', 'GG', 'RA', 'RB']
  const mezzRows = ['MA', 'MB', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH']

  // SCREEN 1
  if (frontRows.includes(seatRow)) {
    return 1
  }
  // SCREEN 2
  if (middleRows.includes(seatRow) && seatNumber > 100) {
    return 2
  }
  // SCREEN 3
  if (middleRows.includes(seatRow) && (seatNumber & 1) && seatNumber < 100) {
    return 3
  }
  // SCREEN 4
  if (middleRows.includes(seatRow) && (!seatNumber & 1) && seatNumber < 100) {
    return 4
  }
  // SCREEN 5
  if (mezzRows.includes(seatRow) && seatNumber > 100) {
    return 5
  }
  // SCREEN 6
  if (mezzRows.includes(seatRow) && (seatNumber & 1) && seatNumber < 100) {
    return 6
  }
  // SCREEN 7
  if (mezzRows.includes(seatRow) && (!seatNumber & 1) && seatNumber < 100) {
    return 7
  }
}

/**
 * CYOP CART (BUTTON)
 * Display the cart buttonDisplay the cart button
 * @param {int} packageId
 * @param {object} cartItems
 * @param {object} cartAPIPackageProduct
 */
export const button = (packageId, cartItems, cartAPIPackageProduct) => {
  // Loop Cart Items
  cartItems.forEach(cartItem => {
    // Event & Instance Ids
    const eventId = parseInt(cartItem.dataset.tnProductionSeason, 10)
    const instanceId = parseInt(cartItem.getAttribute('data-tn-performance'), 10)

    // API Package Products
    const cartLineItem = cartAPIPackageProduct.LineItems
      .filter(lineItem => lineItem.Performance && lineItem.Performance.Id === instanceId)[0]

    // API Cart Subline Items
    const cartSubLineItems = cartLineItem.SubLineItems

    // Add Button if Row (subline item is seated)
    if (cartSubLineItems[0].Seat.Row) {
      // Map Screen Id
      const rootSeat = cartSubLineItems[0].Seat
      const screenId = mapScreenId(rootSeat)

      // Create Amend Seats Button
      const ammendSeatsButton = document.createElement('button')
      ammendSeatsButton.classList.add('amend-seats-btn', 'btn', 'btn-primary')
      ammendSeatsButton.innerHTML = 'View or Change Seats'

      // Amends Seats Click
      ammendSeatsButton.addEventListener('click', function () {
        // Trigger load
        const packagesWrapper = document.getElementsByClassName('tn-cart__section--packages')[0]
        const newPackageElement = document.createElement('div')
        newPackageElement.classList.add('custom-cyop-background')
        newPackageElement.innerHTML = `
          <div class="custom-cyop-busy-indicator custom-cyop-busy-indicator--spinning">
            <div class="custom-cyop-busy-indicator__content">
              <div class="custom-cyop-busy-indicator__message">Loading</div>
            </div>
          </div>
        `
        packagesWrapper.prepend(newPackageElement)

        // Local Storage
        localStorage.setItem('cyop_syos', JSON.stringify({
          packageId: parseInt(packageId, 10),
          eventId: eventId,
          instanceId: instanceId,
          screenId: screenId,
          seats: cartSubLineItems
        }))
        setTimeout(function () {
          window.location.href = `/${eventId}/${instanceId}?custom=cyop`
        }, 5)
      }, false)

      // Add Button to Item
      cartItem.append(ammendSeatsButton)
    }
  })
}

/**
 * CYOP CART (UPDATE)
 * Update the cart
 */
export const update = () => {
}
